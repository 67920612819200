import React from 'react';
import { connect } from 'react-redux';
import { headerBackgroundColors } from '../styles';
import { ManageThemeContext } from '../ThemeManager';
import { Wrapper } from './Wrapper';
import { ButtonPrimary } from '../ButtonPrimary';
import { toggleMenuBar } from '../actions/ui';
import { MenuToggle } from '../MenuToggle';
import { Logo } from './Logo';
import { ReactComponent as LogoSVG } from '../img/logo.svg';
import { Link } from 'react-router-dom';

export class HeaderPresentational extends React.Component<any, any> {
  static contextType = ManageThemeContext;

  state = {
    headerState: 'show',
    headerStateChange: false
  };

  scrollPos: number = 0;
  scrollThrottle: number = 100;
  scrollThreshold: number = 60;

  stateChangeCallback = () => {
    setTimeout(() => {
      this.setState({ headerStateChange: false });
    }, 400)
  }

  handleMenuToggleClick = () => {
    this.props.handleMenuBar(this.props.menuBarStatus === 0 ? 1 : 0);
  };

  render () {

    const theme:any = this.context;
    const { headerState } = this.state;

    return (
      <Wrapper
        headerState={headerState}
        newColor={theme.mode === 'light'
          ? headerBackgroundColors.light
          : headerBackgroundColors.dark}
        playTransition={false}
        theme={theme.mode}
        showHeader={true}
        hideHeader={false}
      >

        <a href="/">
          <Logo>
            <LogoSVG />
          </Logo>
        </a>
        <div>
          <Link
            to="/what-is-a-construction-management-plan"
            className='tab-hidden'
          >
            <ButtonPrimary
              label="What is a Construction Management Plan?"
            />
          </Link>
          <Link
            to="/about"
            className='tab-hidden'
          >
            <ButtonPrimary
              label="About Us"
            />
          </Link>
          <Link
            to="/contact"
            className='tab-hidden'
          >
            <ButtonPrimary
              label="Contact"
            />
          </Link>
          <span>
            <button onClick={() => {
              this.handleMenuToggleClick();
            }}
              className='tab-only'
              aria-label="Toggle Menu"
            >
              <MenuToggle
                textColor={this.props.textColor}
              />
            </button>
          </span>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  menuBarStatus: state.ui.menuBarStatus
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  handleMenuBar: (status: any) => dispatch(toggleMenuBar(status))
});

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPresentational);

export default Header;