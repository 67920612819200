import React from 'react';
import styled from 'styled-components';
import { mediaContainer } from '../../../media';
import { containerWidths } from '../../../styles';
import { Element } from 'react-scroll';
import { Button } from '../../../Button';
import { Link } from 'react-router-dom';

export const About: React.FC = () => {

  const Wrapper = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    align-content: center;
    width: 100%;
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    ${mediaContainer};

    button {
      margin: 0em 0.6em 0.75rem 0;
      font-size: 0.9rem;

      &:first-child {
        margin-left: 0;
      }

      @media(min-width: ${containerWidths.mobile + 1}px) {
        margin: 0em 0.5em 1em 0.4em;
        font-size: 1rem;
      }
      @media(min-width: ${containerWidths.tablet + 1}px) {
        margin: 0em 0.75em 1em 0.5em;
      }
    }

    .back {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .anc {
      position: absolute;
      top: 0px;
      left: 0px;
    }
   
    > div {
      display: flex;
      flex: 1;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;
      width: 100%;
      max-width: 1100px;
      word-wrap: break-word;
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;

      > .specialisation {
        margin: 1.5rem 0;

        h1, h2, h3, h4, p {
          margin-left: 0;
          margin-right: 0;
        }

        p, li {
          font-size: 1rem;
          line-height: 1.6rem;
          font-weight: 600;
        }
        ul {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .footer {
      margin-top: 0;
    }
  `;

  return (
    <>
      <Wrapper>
        <div>
          <div className='specialisation'>
            <Element className='anc' name="about"></Element>

            <h2>About CMPConstruct</h2>

            <h3>CMP Construct's team consists of Construction Professionals who are experienced in the delivery of clear and concise Construction Management Plans for construction projects and demolition works, in line with the specific requirements of each of the major London Borough’s planning guidelines.</h3>

            <h3>Our experience of projects ranges from basement extensions for single dwellings to large multi-phased multi-million-pound projects. We develop in-house, as part of our CMP service, a specific construction methodology, programme and phasing strategy for each project we prepare the CMP for, and in addition, we can organise supporting information such as swept path analysis, noise survey etc. should they be required.</h3>

            <h3>On larger projects in London, where Transport for London (TfL) are impacted by the works, the provision of a Construction Logistics Plan (CLP) is now required in addition to a CMP, CMPConstruct are experienced in preparing CLP’s in line with the TfL guidelines for projects across Central London.</h3>

            <h3>We normally can complete a simple CMP within 7-10 days, larger schemes are reviewed project by project and the duration is given with our fee proposal.</h3>


            <section></section>

            <Link to="/what-is-a-construction-management-plan">
              <Button label="Read more about Construction Management Plans" />
            </Link>

            <Link to="/about">
              <Button label="About Us" />
            </Link>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default About;