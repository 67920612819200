import React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from './Wrapper';
import { toggleMenuBar } from '../actions/ui';
import { ReactComponent as LogoSVG } from '../img/logo.svg';
import { Link } from 'react-router-dom';

export class MenuPresentational extends React.Component<any> {

  handleMenuToggleClick = () => {
    this.props.toggleMenuBar(this.props.menuBarStatus === 0 ? 1 : 0);
  };

  render () {

    return (
      <Wrapper
        menuOpen={this.props.menuBarStatus}
        transitionMenu={this.props.menuBarTransition}
      >
        <div className='content'>
          <div>
            <div className='close'>
              <button onClick={() => { this.handleMenuToggleClick() }}>Close</button>
            </div>
          </div>

          <div className='logo'>
            <LogoSVG />
          </div>

          <div>
            <a href="/">Home</a>
          </div>

          <div>
            <Link to="/about">About Us</Link>
          </div>

          <div>
            <Link to="/contact">Contact</Link>
          </div>

          <div className='subheading'>Learn</div>
          <div>
            <Link
              to="/what-is-a-construction-management-plan"
            >
              What is a Construction Management Plan?
          </Link>
          </div>
        </div>
      </Wrapper>
    );
  }
}


const mapStateToProps = (state: any, ownProps: any) => ({
  menuBarStatus: state.ui.menuBarStatus,
  menuBarTransition: state.ui.menuBarTransition
});

const mapDispatchToProps = {
  toggleMenuBar
};

export const Menu = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuPresentational);

export default Menu;