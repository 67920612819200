import React from 'react'
import { Tooltip } from 'react-tooltip';
import { Wrapper } from './Wrapper'

export const Partners = (props: any) => {

  return (
    <Wrapper>
      <Tooltip />
      <h2>CMPs produced for the following London Boroughs</h2>
      <div>
      <img src={require('../../../img/b18.webp')} alt="Barking &amp; Dagenham" data-tip="Barking" height="45" loading="lazy" />
 <img src={require('../../../img/b17.webp')} alt="Brent" data-tip="Brent" height="45" loading="lazy" />
 <img src={require('../../../img/b6.webp')} alt="Camden" data-tip="Camden" height="45" loading="lazy" />
 <img src={require('../../../img/b4.webp')} alt="City of London" data-tip="City of London" height="45" loading="lazy" />
 <img src={require('../../../img/b11.webp')} alt="Ealing" data-tip="Ealing" height="45" loading="lazy" />
 <img src={require('../../../img/b8.webp')} alt="Enfield" data-tip="Enfield" height="45" loading="lazy" />
 <img src={require('../../../img/b1.webp')} alt="Hammersmith &amp; Fulham" data-tip="Hammersmith &amp; Fulham" height="45" loading="lazy" />
 <img src={require('../../../img/b13.webp')} alt="Haringey" data-tip="Haringey" height="45" loading="lazy" />
 <img src={require('../../../img/b12.webp')} alt="Harrow" data-tip="Harrow" height="45" loading="lazy" />
 <img src={require('../../../img/b9.webp')} alt="Havering" data-tip="Havering" height="45" loading="lazy" />
 <img src={require('../../../img/b19.webp')} alt="Islington" data-tip="Islington" height="45" loading="lazy" />
 <img src={require('../../../img/b3.webp')} alt="Lambeth" data-tip="Lambeth" height="45" loading="lazy" />
 <img src={require('../../../img/b16.webp')} alt="Merton" data-tip="Merton" height="45" loading="lazy" />
 <img src={require('../../../img/b20.webp')} alt="Richmond-upon-Thames" data-tip="Richmond-upon-Thames" height="45" loading="lazy" />
 <img src={require('../../../img/b5.webp')} alt="Royal Borough of Kensington and Chelsea" data-tip="Royal Borough of Kensington and Chelsea" height="45" loading="lazy" />
 <img src={require('../../../img/b2.webp')} alt="Southwark" data-tip="Southwark" height="45" loading="lazy" />
 <img src={require('../../../img/b15.webp')} alt="Tower Hamlets" data-tip="Tower Hamlets" height="45" loading="lazy" />
 <img src={require('../../../img/b10.webp')} alt="Waltham Forest" data-tip="Waltham Forest" height="45" loading="lazy" />
 <img src={require('../../../img/b14.webp')} alt="Wandsworth" data-tip="Wandsworth" height="45" loading="lazy" />
 <img src={require('../../../img/b7.webp')} alt="Westminster" data-tip="Westminster" height="45" loading="lazy" />
</div>
    </Wrapper>
  )
}

export default Partners;