import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { About } from './pages/About';
import { WhatIs } from './pages/WhatIs';
import Home from './pages/Home';
import { Contact } from './pages/Contact/';

export const Router = () => {
  const { pathname } = useLocation();

  // Scroll to top of the window on every page change or network change.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Routes>
      <Route path="/what-is-a-construction-management-plan" element={<WhatIs />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}