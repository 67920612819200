import React, { useState } from 'react';
import { Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Wrapper } from './Wrapper'
import { Button } from '../../Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

export const Contact: React.FC = (props: any) => {
  const [sentMessage] = useState(false);

  return (
    <Wrapper>
      <Element className='anc' name="contactUs"></Element>
      <div className='contact'>
        <p>
          <Link to="/">
            <Button label="< Back Home" />
          </Link>
        </p>
        <div>
          <h1>Contact Us</h1>
          {!sentMessage
            ?
            <>
              <h3>Please contact us with your project details and planning documentation. We'll carefully review your specific requirements and provide a detailed quote within 48 hours</h3>
            </>
            :
            <h3>Thank you for your message. We will review your specific requirements and provide a quote within 48 hours.</h3>
          }

<div style={{ width: '100%' }}>
  <div className='email'>
    <div>
      <a
        href="mailto:cmp@cmpconstruct.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'flex', alignItems: 'center', gap: '3.5px' }}
        aria-label="Email us"
      >
        <span>
          <FontAwesomeIcon icon={faEnvelope as IconProp} transform='grow-4' />
        </span>
        <span>Email us</span>
      </a>
    </div>

    <div>
      <a
        href="https://www.linkedin.com/company/cmpconstruct"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
        aria-label="Connect with us on LinkedIn"
      >
        <span>
          <FontAwesomeIcon icon={faLinkedin as IconProp} transform='grow-8' />
        </span>
        <span>Connect with us on LinkedIn</span>
      </a>
    </div>

              <div className="mb-4">
              Contact Number: <a href="tel:+442079281657">+44 20 7928 1657</a>
              </div>

              <div className="mb-4">
                Office Address: 112, South Block, 1b Belvedere Road, London, SE1 7GD
              </div>
            </div>

            <iframe 
              title="Office Address" 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.64094330147!2d-0.11966083423025646!3d51.50145617963415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c77e6160c3%3A0xa82214684f42ad93!2sBelvedere%20Rd%2C%20South%20Bank%2C%20London%20SE1%207GD%2C%20UK!5e0!3m2!1sen!2sth!4v1614484215873!5m2!1sen!2sth" 
              width="100%" 
              height="600" 
              style={{ border: 0, padding: '1rem' }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Contact;