import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { buttonHoverColor } from '../theme';
import { containerWidths } from '../styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';



export const Footer = () => {

  const Wrapper = styled.div`
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 100%;
    text-align: center;
    flex: 0;
    width: 100%;
    padding: 1.5rem;
    font-weight: bold;
    font-size: 0.9em;
    color: #fff;
    color: #07486e;
    border-top: 1px solid #f2f2f2;
    background: #fcfcfc;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;

      @media(max-width: ${containerWidths.mobile}px) {
        flex-basis: 100%;
        justify-content: center;
        padding: 1rem;
      }

      a {
        color: #07486e;
        margin-left: 2px;
      }
    }

    > label {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-self: flex-end;
      height: inherit;
      align-items: center;
      justify-content: flex-end;
      min-width: 200px;

      @media(hover: hover) and (pointer: fine) {
        transition: color 0.15s;
        &:hover {
          color: ${buttonHoverColor};
        }
      }

      @media(max-width: ${containerWidths.mobile}px) {
        flex-basis: 100%;
        justify-content: center;
      }
    }

        .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
      
   .social-links {
  display: flex;
  gap: 1rem;
  
  a {
    color: #4D9949; /* Update this to match your exact green color code */
    transition: color 0.15s;
    
    &:hover {
      opacity: 0.8;
    }
      }
    }
  `;

  const year = (new Date()).getFullYear();

  return (
    <Wrapper>
      <div className="footer-content">
        <div>
          &copy; CMP Construct Ltd {year}. &nbsp; Built by <a href="https://jkrb.io" target="_blank" rel="noopener noreferrer">JKRB</a>
        </div>
        <div className="social-links">
          <a href="mailto:cmp@cmpconstruct.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope as IconProp} size="lg" />
          </a>
          <a href="https://www.linkedin.com/company/cmpconstruct" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin as IconProp} size="lg" />
          </a>
        </div>
      </div>
    </Wrapper>
  );
}
